import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DynamicBootstrap from './DynamicBootstrap';

const DetalleHuerto = () => {
  const { id } = useParams();

  const [huerto, setHuerto] = useState({
    id: '',
    nombreEstablecimiento: '',
    direccion: '',
    tipoHuertoMedicinal: false,
    tipoHuertoAlimenticioMedicinal: false,
    fechaInicio: '',
    responsableHuerto: '',
    telefonoResponsable: '',
    correoResponsable: '',
    responsablePauta: '',
    telefonoPauta: '',
    correoPauta: ''
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHuerto = async () => {
      try {
        const response = await fetch(`https://www.registroprocesos-mv.greenalchemy.cl/obtener-detalle-huerto.php?id=${id}`);
        if (!response.ok) {
          throw new Error('No se pudo obtener el detalle del huerto');
        }
        const data = await response.json();
        
        console.log(data[0].ID_HUERTO);

        setHuerto({
          id: data[0].ID_HUERTO,
          nombreEstablecimiento: data[0].NOMBRE_HUERTO,
          direccion: data[0].DIRECCION,
          tipoHuertoMedicinal: data[0].TIPO_HUERTO === "Medicinal" || data[0].TIPO_HUERTO === "Ambos",
          tipoHuertoAlimenticioMedicinal: data[0].TIPO_HUERTO === "Alimenticio-medicinal" || data[0].TIPO_HUERTO === "Ambos",
          fechaInicio: data[0].FECHA_INICIO.split(" ")[0],
          responsableHuerto: data[0].NOMBRE_RESPONSABLE,
          telefonoResponsable: data[0].TELEFONO_RESPONSABLE,
          correoResponsable: data[0].CORREO_RESPONSABLE,
          responsablePauta: data[0].RESPONSABLE_PAUTA,
          telefonoPauta: data[0].TELEFONO_RESPONSABLE_PAUTA,
          correoPauta: data[0].CORREO_RESPONSABLE_PAUTA
        });        
        
        console.log(huerto)
      } catch (error) {
        setError(error.message);
      }
    };

    fetchHuerto();
  }, [id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!huerto) {
    return <div>Cargando...</div>;
  }

  return (
    <DynamicBootstrap>

    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <Link to="/ListadoHuertos">
          <button className="btn btn-secondary">
            Volver al listado
          </button>
        </Link>
        <Link to={`/ModificacionHuerto/${id}`}>
          <button className="btn btn-primary">
            Editar
          </button>
        </Link>
      </div>
      <h2 className="text-success text-center">1.- Detalle del Establecimiento y del Huerto</h2>
      <br />
      <div className="mb-3">
        <label className="form-label fw-bold">Nombre establecimiento</label>
        <p>{huerto.nombreEstablecimiento}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Dirección</label>
        <p>{huerto.direccion}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Tipo de huerto</label>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="tipoHuertoMedicinal" checked={huerto.tipoHuertoMedicinal} disabled />
          <label className="form-check-label" htmlFor="tipoHuertoMedicinal">
            Medicinal
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="tipoHuertoAlimenticioMedicinal" checked={huerto.tipoHuertoAlimenticioMedicinal} disabled />
          <label className="form-check-label" htmlFor="tipoHuertoAlimenticioMedicinal">
            Alimenticio-medicinal
          </label>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Fecha de inicio actividades del huerto</label>
        <p>{huerto.fechaInicio}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Responsable(s) del huerto</label>
        <p>{huerto.responsableHuerto}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Teléfono Responsable</label>
        <p>{huerto.telefonoResponsable}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Correo electrónico Responsable</label>
        <p>{huerto.correoResponsable}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Responsable(s) de llenar la pauta de recolección de información</label>
        <p>{huerto.responsablePauta}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Teléfono Responsable Pauta</label>
        <p>{huerto.telefonoPauta}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Correo electrónico Responsable Pauta</label>
        <p>{huerto.correoPauta}</p>
      </div>
    </div>
    </DynamicBootstrap>

  );
};

export default DetalleHuerto;
