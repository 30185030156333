import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'primeflex/primeflex.css';
import { Card } from 'primereact/card';
import Menu from './Menu';
import Home from './Home';
import ListadoHuertos from './ListadoHuertos';
import FormularioIdentificacionHuerto from './Formulario_IdentificacionHuerto';
import ModificacionHuerto from './ModificacionHuerto';
import DetalleHuerto from './DetalleHuerto';

import ListadoTrabajores from './ListadoTrabajadores';
import Formulario_IdentificacionTrabajador from './Formulario_IdentificacionTrabajador';
import ModificacionTrabajador from './ModificacionTrabajador';
import DetalleTrabajador from './DetalleTrabajador';

import './App.css';

function App() {
    return (
        <Router>
            <div>
                <Menu />
                <div className="container">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-12 p-md-10 p-lg-8">
                            <Routes>
                                <Route path="/Home" element={<Home />} />

                                <Route path="/ListadoHuertos" element={<ListadoHuertos />} />
                                <Route path="/Formulario_IdentificacionHuerto" element={<FormularioIdentificacionHuerto />} />
                                <Route path="/ModificacionHuerto/:id" element={<ModificacionHuerto />} />
                                <Route path="/DetalleHuerto/:id" element={<DetalleHuerto />} />

                                <Route path="/ListadoTrabajadores" element={<ListadoTrabajores />} />
                                <Route path="/Formulario_IdentificacionTrabajador" element={<Formulario_IdentificacionTrabajador />} />
                                <Route path="/ModificacionTrabajador/:id" element={<ModificacionTrabajador />} />
                                <Route path="/DetalleTrabajador/:id" element={<DetalleTrabajador />} />
                                
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
