// Menu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'; // Importa PrimeFlex
import logo from './MagiaVerdeLogo.png'; // Importa tu logo

function Menu() {
    const items = [
        {
            label: 'Huertos',
            icon: 'pi pi-fw pi-leaf',
            items: [
                {
                    label: 'Listado de Huertos',
                    icon: 'pi pi-fw pi-list',
                    template: () => <Link to="/ListadoHuertos" className="p-menuitem-link">Listado de Huertos</Link>
                },
                {
                    label: 'Crear Nuevo Huerto',
                    icon: 'pi pi-fw pi-plus',
                    template: () => <Link to="/Formulario_IdentificacionHuerto" className="p-menuitem-link">Crear Nuevo Huerto</Link>
                }
            ]
        },
        {
            label: 'Trabajadores',
            icon: 'pi pi-fw pi-leaf',
            items: [
                {
                    label: 'Listado de Trabajadores',
                    icon: 'pi pi-fw pi-list',
                    template: () => <Link to="/ListadoTrabajadores" className="p-menuitem-link">Listado de Trabajadores</Link>
                },
                {
                    label: 'Crear Nuevo Trabajador',
                    icon: 'pi pi-fw pi-plus',
                    template: () => <Link to="/Formulario_IdentificacionTrabajador" className="p-menuitem-link">Crear Nuevo Trabajador</Link>
                }
            ]
        }
        // Puedes agregar más elementos de menú aquí
    ];

    const start = (
        <Link to="/Home" className="p-mr-2">
            <img src={logo} alt="Logo" height="40" className="p-mr-2" style={{ maxWidth: '150px' }} />
        </Link>
    );

    return (
        <div className="p-shadow-2 p-p-3" style={{ marginBottom: '1rem' }}>
            <div className="p-grid p-justify-center">
                <div className="p-col-12 p-md-10 p-lg-8">
                    <Menubar model={items} start={start} />
                </div>
            </div>
        </div>
    );
}

export default Menu;
