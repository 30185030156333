import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function Home() {


    return (
        <div>
            <div className="p-mt-4 p-p-4">
                <Panel header="Bienvenido al Registro de Procesos Magia Verde" className="p-shadow-3">
                    <h1>¡Gracias por utilizar nuestra plataforma para gestionar tus procesos de forma eficiente!</h1>
                    <Link label="Empezar" icon="pi pi-check" className="p-button p-button-success p-mt-3" to="/ListadoHuertos">Huertos</Link>
                    
                </Panel>
            </div>
        </div>
    );
}

export default Home;
