import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css';  // Tema de PrimeReact
import 'primereact/resources/primereact.min.css';         // CSS de PrimeReact
import 'primeicons/primeicons.css';                       // Iconos de PrimeIcons
import 'primeflex/primeflex.css';                         // CSS de PrimeFlex

function ListadoTrabajadores() {
    const [trabajadores, setTrabajadores] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [rows, setRows] = useState(10);

    const obtenerTrabajadores = async () => {
        try {
            const response = await fetch('https://www.registroprocesos-mv.greenalchemy.cl/listado-trabajadores.php');
            if (response.ok) {
                const data = await response.json();
                setTrabajadores(data);
            } else {
                console.error('Error al obtener los trabajadores:', response.statusText);
            }
        } catch (error) {
            console.error('Error al obtener los trabajadores:', error);
        }
    };

    useEffect(() => {
        obtenerTrabajadores();
    }, []);

    const getEstado = (activo) => activo === "1" ? 'Vigente' : 'No Vigente';

    const cambiarEstado = async (id, nuevoEstado) => {
        try {
            const response = await fetch(`https://www.registroprocesos-mv.greenalchemy.cl/cambiar-estado-trabajador.php?id=${id}&estado=${nuevoEstado}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id, estado: nuevoEstado })
            });
            if (response.ok) {
                obtenerTrabajadores();
            } else {
                console.error('Error al cambiar el estado del Trabajador:', response.statusText);
            }
        } catch (error) {
            console.error('Error al cambiar el estado del Trabajador:', error);
        }
    };

    const getBotonEliminarHabilitar = (activo, id) => {
        const nuevoEstado = activo === "1" ? "0" : "1";
        return (
            <Button 
                className={`p-button ${activo === "1" ? 'p-button-danger' : 'p-button-success'} me-2`} 
                onClick={() => cambiarEstado(id, nuevoEstado)}
                title={activo === "1" ? 'Deshabilitar Trabajador' : 'Habilitar Trabajador'}
            >
                <i className={`pi ${activo === "1" ? 'pi-trash' : 'pi-check'}`}></i>
            </Button>
        );
    };

    const rowsOptions = [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
    ];

    return (
        <div className="p-grid p-dir-col p-container"> {/* Agrega la clase p-container */}
            <div className="p-col">
                <h2 className="text-primary text-center">Listado de Trabajadores</h2>
                <Link to="/Formulario_IdentificacionTrabajador" className="p-button p-button-success">Crear Nuevo Trabajador</Link>
            </div>
            <br></br>
            <div className="p-col text-right">
                <div className="p-field text-right">
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
                </div>
            </div>
            <div className="p-col">
                <DataTable value={trabajadores} paginator rows={rows} globalFilter={globalFilter} responsiveLayout="scroll" paginatorPosition="bottom" className="p-datatable-gridlines" emptyMessage="Sin datos de Trabajadores.">
                    <Column field="ID_TRABAJADOR" header="ID" sortable />
                    <Column field="NOMBRE" header="Nombre" sortable />
                    <Column field="DIRECCION" header="Ubicación" sortable />
                    <Column field="TIPO_TRABAJADOR" header="Tipo" sortable />
                    <Column field="ACTIVO" header="Estado" body={(rowData) => getEstado(rowData.ACTIVO)} sortable />
                    <Column header="Acciones" body={(rowData) => (
              <React.Fragment>
              <Link to={`/DetalleTrabajador/${rowData.ID_TRABAJADOR}`} className="p-button p-button-info me-2 " title="Ver Detalles">
                  <span className="pi pi-eye"></span>
              </Link>
              <Link to={`/ModificacionTrabajador/${rowData.ID_TRABAJADOR}`} className="p-button p-button-warning me-2" title="Editar Trabajador">
                  <span className="pi pi-pencil"></span>
              </Link>
              {getBotonEliminarHabilitar(rowData.ACTIVO, rowData.ID_TRABAJADOR)}
          </React.Fragment>
                    )} />
                </DataTable>

            </div>
            <div className="p-col text-center"> {/* Centra horizontalmente el contenido */}
                <Dropdown value={rows} options={rowsOptions} onChange={(e) => setRows(e.value)} placeholder="Número de filas" />
            </div>
        </div>

    );
}

export default ListadoTrabajadores;
