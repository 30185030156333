import React, { useState } from 'react';
import DynamicBootstrap from './DynamicBootstrap';

function IdentificacionTrabajador() {
  const [formData, setFormData] = useState({
    nombreEstablecimiento: '',
    direccion: '',
    tipoTrabajadorJardinero: false,
    tipoTrabajadorSoporte: false,
    telefonoTrabajador: '',
    correoTrabajador: '',

  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.nombreEstablecimiento) newErrors.nombreEstablecimiento = 'Este campo es obligatorio';
    if (!formData.direccion) newErrors.direccion = 'Este campo es obligatorio';
    if (!formData.telefonoTrabajador) newErrors.telefonoTrabajador = 'Este campo es obligatorio';
    if (!formData.correoTrabajador) newErrors.correoTrabajador = 'Este campo es obligatorio';

    return newErrors;
  };

  const handleClickGuardar = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
 
    setIsSubmitting(true);

    let tipoTrabajador = "";

    if (formData.tipoTrabajadorJardinero) 
      tipoTrabajador= 'Jardinero';

    if (formData.tipoTrabajadorSoporte) 
      tipoTrabajador= 'Soporte';

    if (formData.tipoTrabajadorJardinero && formData.tipoTrabajadorSoporte) 
      tipoTrabajador= 'Ambos';

    const data = {
      nombreTrabajador: formData.nombreEstablecimiento,
      direccion: formData.direccion,
      tipoTrabajador: tipoTrabajador,
      telefonoTrabajador: formData.telefonoTrabajador,
      correoTrabajador: formData.correoTrabajador,

    };

    try {
      const response = await fetch('https://www.registroprocesos-mv.greenalchemy.cl/guardar-trabajador.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setSuccessMessage('Guardado exitoso');
        setFormData({
          nombreEstablecimiento: '',
          direccion: '',
          tipoTrabajadorSoporte: false,
          tipoTrabajadorJardinero: false,
          telefonoTrabajador: '',
          correoTrabajador: '',
        });
      } else {
        setSuccessMessage('Error al guardar');
      }
    } catch (error) {
      console.error('Error al guardar:', error);
      setSuccessMessage('Error al guardar');
    }
  };


  return (
    <DynamicBootstrap>
    <div className="container">
      <h2 className="text-success">1.- Identificación del Trabajador</h2>
      <br />
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <div className="mb-3">
        <label htmlFor="nombreEstablecimiento" className="form-label fw-bold">Nombre Completo Trabajador</label>
        <input type="text" className="form-control" id="nombreEstablecimiento" value={formData.nombreEstablecimiento} onChange={handleChange} />
        {errors.nombreEstablecimiento && <div className="text-danger">{errors.nombreEstablecimiento}</div>}
      </div>
      <div className="mb-3">
        <label htmlFor="direccion" className="form-label fw-bold">Dirección</label>
        <input type="text" className="form-control" id="direccion" value={formData.direccion} onChange={handleChange} />
        {errors.direccion && <div className="text-danger">{errors.direccion}</div>}
      </div>
      <div className="mb-3">
        <label htmlFor="tipoTrabajador" className="form-label fw-bold">Tipo de Trabajador</label>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="tipoTrabajadorJardinero" checked={formData.tipoTrabajadorJardinero} onChange={handleChange} />
          <label className="form-check-label" htmlFor="tipoTrabajadorJardinero">
            Jardinero
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="tipoTrabajadorSoporte" checked={formData.tipoTrabajadorSoporte} onChange={handleChange} />
          <label className="form-check-label" htmlFor="tipoTrabajadorSoporte">
            Soporte 
          </label>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="telefonoTrabajador" className="form-label fw-bold">Teléfono Trabajador</label>
        <input type="tel" className="form-control" id="telefonoTrabajador" value={formData.telefonoTrabajador} onChange={handleChange} />
        {errors.telefonoTrabajador && <div className="text-danger">{errors.telefonoTrabajador}</div>}
      </div>
      <div className="mb-3">
        <label htmlFor="correoTrabajador" className="form-label fw-bold">Correo electrónico Trabajador</label>
        <input type="email" className="form-control" id="correoTrabajador" value={formData.correoTrabajador} onChange={handleChange} />
        {errors.correoTrabajador && <div className="text-danger">{errors.correoTrabajador}</div>}
      </div>
      <br />
      <br></br>
      <div className="mb-3 text-center">
        <button className="btn btn-success btn-lg" onClick={handleClickGuardar}>Guardar</button>
      </div>
    </div>
    </DynamicBootstrap>
  );
}

export default IdentificacionTrabajador;
