import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DynamicBootstrap from './DynamicBootstrap';

const UpdateTrabajador = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        nombreEstablecimiento: '',
        direccion: '',
        tipoTrabajadorJardinero: false,
        tipoTrabajadorSoporte: false,
        telefonoTrabajador: '',
        correoTrabajador: '',
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchTrabajador = async () => {
            try {
                const response = await fetch(`https://www.registroprocesos-mv.greenalchemy.cl/obtener-detalle-trabajador.php?id=${id}`);
                if (!response.ok) {
                    throw new Error('No se pudo obtener el detalle del trabajador');
                }
                const data = await response.json();
                console.log(data);

                setFormData({
                    nombreEstablecimiento: data[0].NOMBRE,
                    direccion: data[0].DIRECCION,
                    tipoTrabajadorJardinero: data[0].TIPO_TRABAJADOR === "Jardinero" || data[0].TIPO_TRABAJADOR === "Ambos",
                    tipoTrabajadorSoporte: data[0].TIPO_TRABAJADOR === "Soporte" || data[0].TIPO_TRABAJADOR === "Ambos",
                    telefonoTrabajador: data[0].TELEFONO,
                    correoTrabajador: data[0].CORREO,
                });

                console.log(formData);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchTrabajador();
    }, [id]);

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: type === 'checkbox' ? checked : value
        }));
    };

    const handleClickGuardar = async () => {
        try {
            const response = await fetch(`https://www.registroprocesos-mv.greenalchemy.cl/actualizar-trabajador.php?id=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...formData, id_Trabajador: id }) 
            });

            if (!response.ok) {
                throw new Error('No se pudo actualizar el trabajador');
            }

            setSuccessMessage('trabajador actualizado exitosamente');
            alert('trabajador actualizado exitosamente');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <DynamicBootstrap>

        <div className="container">
            <div className="mb-3">
                <Link to="/ListadoTrabajadores">
                    <button className="btn btn-secondary">
                        Volver al listado
                    </button>
                </Link>
            </div>
            <br />
            <h2 className="text-success text-center">1.- Modificación del Trabajador</h2>
            <br />
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <div className="mb-3">
                <label htmlFor="nombreEstablecimiento" className="form-label fw-bold">Nombre Trabajador</label>
                <input type="text" className="form-control" id="nombreEstablecimiento" value={formData.nombreEstablecimiento} onChange={handleChange} />
                {errors.nombreEstablecimiento && <div className="text-danger">{errors.nombreEstablecimiento}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="direccion" className="form-label fw-bold">Dirección</label>
                <input type="text" className="form-control" id="direccion" value={formData.direccion} onChange={handleChange} />
                {errors.direccion && <div className="text-danger">{errors.direccion}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="tipoTrabajador" className="form-label fw-bold">Tipo de Trabajador</label>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="tipoTrabajadorJardinero" checked={formData.tipoTrabajadorJardinero} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="tipoTrabajadorJardinero">
                        Jardinero
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="tipoTrabajadorSoporte" checked={formData.tipoTrabajadorSoporte} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="tipoTrabajadorSoporte">
                        Soporte
                    </label>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="telefonoTrabajador" className="form-label fw-bold">Teléfono Trabajador</label>
                <input type="tel" className="form-control" id="telefonoTrabajador" value={formData.telefonoTrabajador} onChange={handleChange} />
                {errors.telefonoTrabajador && <div className="text-danger">{errors.telefonoTrabajador}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="correoTrabajador" className="form-label fw-bold">Correo electrónico Trabajador</label>
                <input type="email" className="form-control" id="correoTrabajador" value={formData.correoTrabajador} onChange={handleChange} />
                {errors.correoTrabajador && <div className="text-danger">{errors.correoTrabajador}</div>}
            </div>
            <br />
            <br></br>
            <div className="mb-3 text-center">
                <button className="btn btn-success btn-lg" onClick={handleClickGuardar}>Guardar</button>
            </div>
        </div>
        </DynamicBootstrap>

    );
}

export default UpdateTrabajador;
