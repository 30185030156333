import React from 'react';
import IdentificacionEstablecimientoHuerto from './IdentificacionEstablecimientoHuerto';
import { Link } from 'react-router-dom';
import { Card } from 'primereact/card';

function Formulario() {
  return (
    <Card className="p-shadow-10 p-p-4 p-mb-4 container">

      <div className="d-flex justify-content-between align-items-center mb-3">
        <Link to="/ListadoHuertos" className="btn btn-secondary">Volver al Listado</Link>
      </div>
      <h1 className="text-center text-success text-decoration-underline">Formulario Registro Huerto</h1>
      <br />
        <IdentificacionEstablecimientoHuerto />

      {/* Agregar más componentes para otros campos de datos si es necesario */}
    </Card>
  );
}

export default Formulario;
