import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DynamicBootstrap from './DynamicBootstrap';

const UpdateHuerto = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        nombreEstablecimiento: '',
        direccion: '',
        tipoHuertoMedicinal: false,
        tipoHuertoAlimenticioMedicinal: false,
        fechaInicio: '',
        responsableHuerto: '',
        telefonoResponsable: '',
        correoResponsable: '',
        responsablePauta: '',
        telefonoPauta: '',
        correoPauta: ''
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchHuerto = async () => {
            try {
                const response = await fetch(`https://www.registroprocesos-mv.greenalchemy.cl/obtener-detalle-huerto.php?id=${id}`);
                if (!response.ok) {
                    throw new Error('No se pudo obtener el detalle del huerto');
                }
                const data = await response.json();
                console.log(data);

                setFormData({
                    nombreEstablecimiento: data[0].NOMBRE_HUERTO,
                    direccion: data[0].DIRECCION,
                    tipoHuertoMedicinal: data[0].TIPO_HUERTO === "Medicinal" || data[0].TIPO_HUERTO === "Ambos" ,
                    tipoHuertoAlimenticioMedicinal: data[0].TIPO_HUERTO === "Alimenticio-medicinal" || data[0].TIPO_HUERTO === "Ambos",
                    fechaInicio: data[0].FECHA_INICIO.split(" ")[0],
                    responsableHuerto: data[0].NOMBRE_RESPONSABLE,
                    telefonoResponsable: data[0].TELEFONO_RESPONSABLE,
                    correoResponsable: data[0].CORREO_RESPONSABLE,
                    responsablePauta: data[0].RESPONSABLE_PAUTA,
                    telefonoPauta: data[0].TELEFONO_RESPONSABLE_PAUTA,
                    correoPauta: data[0].CORREO_RESPONSABLE_PAUTA
                });

                console.log(formData);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchHuerto();
    }, [id]);

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: type === 'checkbox' ? checked : value
        }));
    };

    const handleClickGuardar = async () => {
        try {
            const response = await fetch(`https://www.registroprocesos-mv.greenalchemy.cl/actualizar-huerto.php?id=${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...formData, id_huerto: id }) 
            });

            if (!response.ok) {
                throw new Error('No se pudo actualizar el huerto');
            }

            setSuccessMessage('Huerto actualizado exitosamente');
            alert('Huerto actualizado exitosamente');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <DynamicBootstrap>

        <div className="container">
            <div className="mb-3">
                <Link to="/ListadoHuertos">
                    <button className="btn btn-secondary">
                        Volver al listado
                    </button>
                </Link>
            </div>
            <br />
            <h2 className="text-success text-center">1.- Modificación del Establecimiento y del Huerto</h2>
            <br />
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <div className="mb-3">
                <label htmlFor="nombreEstablecimiento" className="form-label fw-bold">Nombre establecimiento</label>
                <input type="text" className="form-control" id="nombreEstablecimiento" value={formData.nombreEstablecimiento} onChange={handleChange} />
                {errors.nombreEstablecimiento && <div className="text-danger">{errors.nombreEstablecimiento}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="direccion" className="form-label fw-bold">Dirección</label>
                <input type="text" className="form-control" id="direccion" value={formData.direccion} onChange={handleChange} />
                {errors.direccion && <div className="text-danger">{errors.direccion}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="tipoHuerto" className="form-label fw-bold">Tipo de huerto</label>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="tipoHuertoMedicinal" checked={formData.tipoHuertoMedicinal} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="tipoHuertoMedicinal">
                        Medicinal
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="tipoHuertoAlimenticioMedicinal" checked={formData.tipoHuertoAlimenticioMedicinal} onChange={handleChange} />
                    <label className="form-check-label" htmlFor="tipoHuertoAlimenticioMedicinal">
                        Alimenticio-medicinal
                    </label>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="fechaInicio" className="form-label fw-bold">Fecha de inicio actividades del huerto</label>
                <input type="date" className="form-control" id="fechaInicio" value={formData.fechaInicio} onChange={handleChange} />
                {errors.fechaInicio && <div className="text-danger">{errors.fechaInicio}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="responsableHuerto" className="form-label fw-bold">Responsable(s) del huerto</label>
                <input type="text" className="form-control" id="responsableHuerto" value={formData.responsableHuerto} onChange={handleChange} />
                {errors.responsableHuerto && <div className="text-danger">{errors.responsableHuerto}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="telefonoResponsable" className="form-label fw-bold">Teléfono Responsable</label>
                <input type="tel" className="form-control" id="telefonoResponsable" value={formData.telefonoResponsable} onChange={handleChange} />
                {errors.telefonoResponsable && <div className="text-danger">{errors.telefonoResponsable}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="correoResponsable" className="form-label fw-bold">Correo electrónico Responsable</label>
                <input type="email" className="form-control" id="correoResponsable" value={formData.correoResponsable} onChange={handleChange} />
                {errors.correoResponsable && <div className="text-danger">{errors.correoResponsable}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="responsablePauta" className="form-label fw-bold">Responsable(s) de llenar la pauta de recolección de información</label>
                <input type="text" className="form-control" id="responsablePauta" value={formData.responsablePauta} onChange={handleChange} />
                {errors.responsablePauta && <div className="text-danger">{errors.responsablePauta}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="telefonoPauta" className="form-label fw-bold">Teléfono Responsable Pauta</label>
                <input type="tel" className="form-control" id="telefonoPauta" value={formData.telefonoPauta} onChange={handleChange} />
                {errors.telefonoPauta && <div className="text-danger">{errors.telefonoPauta}</div>}
            </div>
            <div className="mb-3">
                <label htmlFor="correoPauta" className="form-label fw-bold">Correo electrónico Responsable Pauta</label>
                <input type="email" className="form-control" id="correoPauta" value={formData.correoPauta} onChange={handleChange} />
                {errors.correoPauta && <div className="text-danger">{errors.correoPauta}</div>}
            </div>
            <br />
            <br></br>
            <div className="mb-3 text-center">
                <button className="btn btn-success btn-lg" onClick={handleClickGuardar}>Guardar</button>
            </div>
        </div>
        </DynamicBootstrap>

    );
}

export default UpdateHuerto;
