import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DynamicBootstrap from './DynamicBootstrap';

const DetalleTrabajador = () => {
  const { id } = useParams();

  const [Trabajador, setTrabajador] = useState({
    id: '',
    nombreEstablecimiento: '',
    direccion: '',
    tipoTrabajadorJardinero: false,
    tipoTrabajadorSoporte: false,
    telefonoTrabajador: '',
    correoTrabajador: '',
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrabajador = async () => {
      try {
        const response = await fetch(`https://www.registroprocesos-mv.greenalchemy.cl/obtener-detalle-trabajador.php?id=${id}`);
        if (!response.ok) {
          throw new Error('No se pudo obtener el detalle del trabajador');
        }
        const data = await response.json();
        
        console.log(data[0].ID_TRABAJADOR);

        setTrabajador({
          id: data[0].ID_TRABAJADOR,
          nombreEstablecimiento: data[0].NOMBRE,
          direccion: data[0].DIRECCION,
          tipoTrabajadorJardinero: data[0].TIPO_TRABAJADOR === "Jardinero" || data[0].TIPO_TRABAJADOR === "Ambos",
          tipoTrabajadorSoporte: data[0].TIPO_TRABAJADOR === "Soporte" || data[0].TIPO_TRABAJADOR === "Ambos",
          telefonoTrabajador: data[0].TELEFONO,
          correoTrabajador: data[0].CORREO,

        });        
        
        console.log(Trabajador)
      } catch (error) {
        setError(error.message);
      }
    };

    fetchTrabajador();
  }, [id]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!Trabajador) {
    return <div>Cargando...</div>;
  }

  return (
    <DynamicBootstrap>

    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <Link to="/ListadoTrabajadores">
          <button className="btn btn-secondary">
            Volver al listado
          </button>
        </Link>
        <Link to={`/ModificacionTrabajador/${id}`}>
          <button className="btn btn-primary">
            Editar
          </button>
        </Link>
      </div>
      <h2 className="text-success text-center">1.- Detalle del Trabajador</h2>
      <br />
      <div className="mb-3">
        <label className="form-label fw-bold">Nombre Trabajador</label>
        <p>{Trabajador.nombreEstablecimiento}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Dirección</label>
        <p>{Trabajador.direccion}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Tipo de Trabajador</label>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="tipoTrabajadorJardinero" checked={Trabajador.tipoTrabajadorJardinero} disabled />
          <label className="form-check-label" htmlFor="tipoTrabajadorJardinero">
            Jardinero
          </label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="tipoTrabajadorSoporte" checked={Trabajador.tipoTrabajadorSoporte} disabled />
          <label className="form-check-label" htmlFor="tipoTrabajadorSoporte">
            Soporte
          </label>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Teléfono Trabajador</label>
        <p>{Trabajador.telefonoTrabajador}</p>
      </div>
      <div className="mb-3">
        <label className="form-label fw-bold">Correo electrónico Trabajador</label>
        <p>{Trabajador.correoTrabajador}</p>
      </div>
    </div>
    </DynamicBootstrap>

  );
};

export default DetalleTrabajador;
