import React, { useState } from 'react';
import DynamicBootstrap from './DynamicBootstrap';

function IdentificacionEstablecimientoHuerto() {
  const [formData, setFormData] = useState({
    nombreEstablecimiento: '',
    direccion: '',
    tipoHuertoMedicinal: false,
    tipoHuertoAlimenticioMedicinal: false,
    fechaInicio: '',
    responsableHuerto: '',
    telefonoResponsable: '',
    correoResponsable: '',
    responsablePauta: '',
    telefonoPauta: '',
    correoPauta: ''
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.nombreEstablecimiento) newErrors.nombreEstablecimiento = 'Este campo es obligatorio';
    if (!formData.direccion) newErrors.direccion = 'Este campo es obligatorio';
    if (!formData.fechaInicio) newErrors.fechaInicio = 'Este campo es obligatorio';
    if (!formData.responsableHuerto) newErrors.responsableHuerto = 'Este campo es obligatorio';
    if (!formData.telefonoResponsable) newErrors.telefonoResponsable = 'Este campo es obligatorio';
    if (!formData.correoResponsable) newErrors.correoResponsable = 'Este campo es obligatorio';
    if (!formData.responsablePauta) newErrors.responsablePauta = 'Este campo es obligatorio';
    if (!formData.telefonoPauta) newErrors.telefonoPauta = 'Este campo es obligatorio';
    if (!formData.correoPauta) newErrors.correoPauta = 'Este campo es obligatorio';

    return newErrors;
  };

  const handleClickGuardar = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true);

    let tipoHuerto = "";

    if (formData.tipoHuertoMedicinal) 
        tipoHuerto= 'Medicinal';

    if (formData.tipoHuertoAlimenticioMedicinal) 
        tipoHuerto= 'Alimenticio-medicinal';

    if (formData.tipoHuertoAlimenticioMedicinal && formData.tipoHuertoMedicinal) 
        tipoHuerto= 'Ambos';

    const data = {
      nombreEstablecimiento: formData.nombreEstablecimiento,
      direccion: formData.direccion,
      tipoHuerto: tipoHuerto,
      fechaInicio: formData.fechaInicio,
      responsableHuerto: formData.responsableHuerto,
      telefonoResponsable: formData.telefonoResponsable,
      correoResponsable: formData.correoResponsable,
      responsablePauta: formData.responsablePauta,
      telefonoPauta: formData.telefonoPauta,
      correoPauta: formData.correoPauta,
    };

    try {
      const response = await fetch('https://www.registroprocesos-mv.greenalchemy.cl/guardar-huerto.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setSuccessMessage('Guardado exitoso');
        setFormData({
          nombreEstablecimiento: '',
          direccion: '',
          tipoHuertoMedicinal: false,
          tipoHuertoAlimenticioMedicinal: false,
          fechaInicio: '',
          responsableHuerto: '',
          telefonoResponsable: '',
          correoResponsable: '',
          responsablePauta: '',
          telefonoPauta: '',
          correoPauta: ''
        });
      } else {
        setSuccessMessage('Error al guardar');
      }
    } catch (error) {
      console.error('Error al guardar:', error);
      setSuccessMessage('Error al guardar');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DynamicBootstrap>
      <div className="container">
        <h2 className="text-success text-center">1.- Identificación del Establecimiento y del Huerto</h2>
        <br />
        {successMessage && <div className="alert alert-success">{successMessage}</div>}
        <div className="mb-3">
          <label htmlFor="nombreEstablecimiento" className="form-label fw-bold">Nombre establecimiento</label>
          <input type="text" className="form-control" id="nombreEstablecimiento" value={formData.nombreEstablecimiento} onChange={handleChange} />
          {errors.nombreEstablecimiento && <div className="text-danger">{errors.nombreEstablecimiento}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="direccion" className="form-label fw-bold">Dirección</label>
          <input type="text" className="form-control" id="direccion" value={formData.direccion} onChange={handleChange} />
          {errors.direccion && <div className="text-danger">{errors.direccion}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="tipoHuerto" className="form-label fw-bold">Tipo de huerto</label>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="tipoHuertoMedicinal" checked={formData.tipoHuertoMedicinal} onChange={handleChange} />
            <label className="form-check-label" htmlFor="tipoHuertoMedicinal">
              Medicinal
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="tipoHuertoAlimenticioMedicinal" checked={formData.tipoHuertoAlimenticioMedicinal} onChange={handleChange} />
            <label className="form-check-label" htmlFor="tipoHuertoAlimenticioMedicinal">
              Alimenticio-medicinal
            </label>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="fechaInicio" className="form-label fw-bold">Fecha de inicio actividades del huerto</label>
          <input type="date" className="form-control" id="fechaInicio" value={formData.fechaInicio} onChange={handleChange} />
          {errors.fechaInicio && <div className="text-danger">{errors.fechaInicio}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="responsableHuerto" className="form-label fw-bold">Responsable(s) del huerto</label>
          <input type="text" className="form-control" id="responsableHuerto" value={formData.responsableHuerto} onChange={handleChange} />
          {errors.responsableHuerto && <div className="text-danger">{errors.responsableHuerto}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="telefonoResponsable" className="form-label fw-bold">Teléfono Responsable</label>
          <input type="tel" className="form-control" id="telefonoResponsable" value={formData.telefonoResponsable} onChange={handleChange} />
          {errors.telefonoResponsable && <div className="text-danger">{errors.telefonoResponsable}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="correoResponsable" className="form-label fw-bold">Correo electrónico Responsable</label>
          <input type="email" className="form-control" id="correoResponsable" value={formData.correoResponsable} onChange={handleChange} />
          {errors.correoResponsable && <div className="text-danger">{errors.correoResponsable}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="responsablePauta" className="form-label fw-bold">Responsable(s) de llenar la pauta de recolección de información</label>
          <input type="text" className="form-control" id="responsablePauta" value={formData.responsablePauta} onChange={handleChange} />
          {errors.responsablePauta && <div className="text-danger">{errors.responsablePauta}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="telefonoPauta" className="form-label fw-bold">Teléfono Responsable Pauta</label>
          <input type="tel" className="form-control" id="telefonoPauta" value={formData.telefonoPauta} onChange={handleChange} />
          {errors.telefonoPauta && <div className="text-danger">{errors.telefonoPauta}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="correoPauta" className="form-label fw-bold">Correo electrónico Responsable Pauta</label>
          <input type="email" className="form-control" id="correoPauta" value={formData.correoPauta} onChange={handleChange} />
          {errors.correoPauta && <div className="text-danger">{errors.correoPauta}</div>}
        </div>
        <br />
        <br></br>
        <div className="mb-3 text-center">
          <button className="btn btn-success btn-lg" onClick={handleClickGuardar} disabled={isSubmitting}>
            {isSubmitting ? 'Guardando...' : 'Guardar'}
          </button>
        </div>
      </div>
    </DynamicBootstrap>
  );
}

export default IdentificacionEstablecimientoHuerto;
